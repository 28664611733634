export const SHOW_MODAL 				= 'SHOW_MODAL';
export const CLOSE_MODAL 				= 'CLOSE_MODAL';
export const FORM_UPDATE_VALUE 			= 'FORM_UPDATE_VALUE';
export const FORM_UPDATE_FREQUENCY		= 'FORM_UPDATE_FREQUENCY';
export const SELECT_INPUT 				= 'SELECT_INPUT';
export const SELECT_PLACE	 			= 'SELECT_PLACE';
export const UPDATE_PLACES 				= 'UPDATE_PLACES';
export const TOGGLE_FIND_SPONSOR 		= 'TOGGLE_FIND_SPONSOR';
export const TOGGLE_CHAT 				= 'TOGGLE_CHAT';
export const TOGGLE_POPUP 				= 'TOGGLE_POPUP';
export const ADD_SPONSOR 				= 'ADD_SPONSOR';
export const REMOVE_SPONSOR				= 'REMOVE_SPONSOR';
export const MAP_LOADED 				= 'MAP_LOADED';
export const SET_CURRENT_POSITION 		= 'SET_CURRENT_POSITION';
export const UPDATE_LOCATION_OPTIONS 	= 'UPDATE_LOCATION_OPTIONS';
export const HIDE_RESULTS 				= 'HIDE_RESULTS';
export const ACTIVE_FORM				= 'ACTIVE_FORM';
export const SHOW_SAVED 				= 'SHOW_SAVED';
export const SHOW_FINDER				= 'SHOW_FINDER';
export const SAVE_PROPOSAL_FORM  		= 'SAVE_PROPOSAL_FORM';
export const SAVE_EMAIL_FORM  			= 'SAVE_EMAIL_FORM';
export const UPDATE_CURRENT_FORM		= 'UPDATE_CURRENT_FORM';
export const FORM_RESET		= 'FORM_RESET';
export const SAVE_TEST_STATE		= 'SAVE_TEST_STATE';
