import React from 'react';
import emailDisplaySrc from '../images/display-graphic_email.png';
import registrationSrc from '../images/display-graphic_registration.png';

/* component that renders an ad visualization dynamically based on which
	package is currently selected */

const PackageAdVisualization = ({ currentPackage, selectedItems }) => {


	if (currentPackage === 'Website') {
		return (
			<div className="ad-display">
					<div className={'box banner-ad ' + (selectedItems[0] ? 'active' : 'inactive')}/>
					<div className={'box cta-ad one ' + (selectedItems[1] ? 'active' : 'inactive')}/>
					<div className="box cta-ad two"/>
					<div className="box cta-ad three"/>
					<div className={'box sidebar-ad ' + (selectedItems[2] ? 'active' : 'inactive')}/>
			</div>
			)
	} else if (currentPackage === 'Email') {
		return (
			<div className="email-display">
				<img src={emailDisplaySrc} alt="Email sponsor package" />
			</div>
		)

	} else {
		return (
			<div className="registration-display">
				<img src={registrationSrc} alt="Registration sponsor package" />
			</div>
		)
	}
};

export default PackageAdVisualization;
