import React, {Component} from 'react';
import {getUserData, initParallax, readCookie} from './helpers/helperFunctions';
import { store } from './store';
import {Provider} from 'react-redux';
import Home from './components/Home';
import {browserHistory, IndexRedirect, IndexRoute, Route, Router} from 'react-router';
import PackageBuilder from './components/PackageBuilder';
import Packages from './components/AllPackages';
import PackageDetails from './components/PackageDetails';
import ProposalFormContainer from './components/ProposalFormContainer';
import {push, syncHistoryWithStore} from 'react-router-redux';
import {getLastPath, reducerKeys, setLastPath, setState} from './helpers/localStorage';
import {saveMarketoInfo} from './actions';

const history 	= syncHistoryWithStore( browserHistory , store );

class App extends Component {
    componentDidMount() {
        // Get the state from the store to save it to localStorage with setState
        let state = store.getState();
        setState( state, reducerKeys );

        // Get the last PathName to set an event listener to save that path to LocalStorage
        // on Refresh
        let path = state.routing.locationBeforeTransitions.pathname;
        setLastPath(path, store);

        // Check if this is the first time visiting. If not get set the Path
        if( getLastPath(store.getState()) !== "/" ) {
            let path = getLastPath(store.getState());
            store.dispatch(push(path));
        }

        var marketoCookie = readCookie('_mkto_trk');
        // var marketoCookie = "id:380-SBA-754&token:_mch-sportsengine.com-1482425189750-16167";
        var userDataPromise = new Promise((res, rej) => {
            if (!marketoCookie) res(null);
            res(getUserData(encodeURI(marketoCookie)));
        });

        userDataPromise.then((userInfo) => {
            store.dispatch(saveMarketoInfo(userInfo));
        });

        initParallax();
    }

    render() {
        return (
            <Provider store={store}>
                <Router onUpdate={() => window.scrollTo(0, 300)} history={history}>
                    <Route path="/" component={Home}>
                        <IndexRedirect to="packages" />
                        <Route path="/packages" components={ PackageBuilder }>
                            <IndexRoute component={ Packages } />
                            <Route path="/packages/:packageName" component={ PackageDetails }/>
                            <Route path="/packages/:packageName/:proposal" component={ ProposalFormContainer } />
                        </Route>
                    </Route>
                </Router>
            </Provider>
        );
    }
}

export default App;
