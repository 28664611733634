import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunk from 'redux-thunk';
import {getState, resetState} from './helpers/localStorage';
import {routerMiddleware} from 'react-router-redux';
import { browserHistory } from 'react-router';
import * as Reducers from './reducers';

const routerMW = routerMiddleware(browserHistory);

resetState();

export const store = getState() ?
    createStore( combineReducers(Reducers), getState(), applyMiddleware( thunk, routerMW ) ) :
    createStore( combineReducers(Reducers), applyMiddleware( thunk, routerMW ) ) ;