import React from 'react';
import LocationCard from './LocationCard';
import MarketoGhostForm from './MarketoGhostForm';
import { getForm } from '../helpers/helperFunctions';

const LocationResults = ({ locations = [], back, saved }) => {
	return (
		<div className="location-results-container">
		  <span className="locations-link" onClick={ (e) => back() }>Search Again</span>
	      <div className="location-results">
	      	{ locations.map((location, i ) => {
	  		return (
          	<LocationCard key={i} savedSponsor={saved} info={location} />);
	  		  }) }
	      </div>
	      <MarketoGhostForm ID={ getForm('Find') }/>
        </div>
    );
}

export default LocationResults;
