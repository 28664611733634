import React from 'react';
import { connect } from 'react-redux';
class MarketoGhostForm extends React.Component {
  constructor(props) {
    super(props);
    window.MktoForms2.loadForm("//app-ab06.marketo.com", "380-SBA-754", props.ID, this.handleFormSubmission.bind(this) );
  }

  handleFormSubmission(form) {
      const { email, userid } = this.props;
      if( email && userid ){
          form.vals({ "Email": email || "none", "First": `${userid}` });
          form.submit();
          form.onSuccess( result => false );
      }
  }

  render() {
  	const formID = `mktoForm_${this.props.ID}`;
    return (
      <form style={{ display: 'none' }} name={formID} id={formID}></form>
    );
  }
}

const mapStateToProps = ({ marketoInformation: { email, userid } }) => ({
  email, userid
});

export default connect( mapStateToProps )( MarketoGhostForm );