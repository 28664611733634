/* eslint-disable no-unused-vars,react/jsx-no-target-blank */
import React from 'react';
import { connect } from 'react-redux';
import {addToSponsors, removeSponsor} from '../actions';

const mapStateToProps = ({ sponsors }) => ({
      sponsors
});
const mapDispatchToProps = dispatch => ({
      addSponsor: (obj) => dispatch(addToSponsors(obj)),
      removeSponsor: (obj) => dispatch(removeSponsor(obj))
});

class LocationCard extends React.Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const { info } = nextProps;

        if (nextProps.sponsors !== prevState.currentProps.sponsors && info) {
            return {
                currentProps: nextProps,
                checkedLocation: nextProps.sponsors.filter((sponsor, i) => {
                    return sponsor.id === info.id;
                })
            }
        }

        return {
            ...prevState,
            currentProps: nextProps
        };
    }
    state = {
        currentProps: this.props,
        checkedLocation: [],
    };

      render(){
          const { checkedLocation } = this.state;
            let { info, addSponsor } = this.props;
            let icon = checkedLocation.length > 0 ?
                        (<span className={'saved' }><span className="icon-checkmark-small"></span></span>) :
                        (<span className={ 'notsaved' }><span className="plus-symbol icon-plus-small"></span></span>);


      	return !!info && (
                  <div className="location-card" onClick={ () => addSponsor(info) } >
                        <div className="add-card-wrapper">
                  	    {icon}
                        </div>
                        <div className="card-content">
                        	<h4>{ info.name }</h4>
                        	<div className="location-card-details">
                        		<span>{info.vicinity}</span><br />
                                    <span>{ info.formatted_phone_number || '' }</span><br />
                                    <span><a href={info.website} target="_blank">View Website</a></span>
                        	</div>
                        </div>
                  </div>
            );
      }
}

export default connect( mapStateToProps, mapDispatchToProps )(LocationCard);
