/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router';

function marketoSub(pathTo) {
	window.Munchkin && window.Munchkin.munchkinFunction('clickLink',{href: 'sponsorship-app/viewed-sponsorship-builder'});
	window.ga('send', 'event', 'Sponsorship', window.location.pathname, pathTo && pathTo.slice(10));
}

const Card = ({ info, openModal }) => {
        const pathTo = `/packages/${info.packageName}`;
		return (
			<div className="package-card-wrapper">

				<div className="package-card-image">
					<img src={`${info.packageImage}`} alt={`${info.packageImageDescription}`} />
				</div>

				<div className="package-card-text-wrapper">
					<h3>{ info.packageName } Sponsorships</h3>
					<p className="smaller">{ info.packageDescription }</p>
					<Link 
						to={ pathTo }
						className="button package-selector"
						onClick={ () => marketoSub(pathTo) }>Select</Link>
					<a className="package-learn-more-link" onClick={() => openModal(info.packageName, 'main') } >What's this?</a>
				</div>

				<div className="symbol-bubble package-card-icon">
					<span className={info.packageIcon}></span>
				</div>

			</div>

		);

};

export default Card;
