import React from 'react';
import { Link } from 'react-router';

function marketoSub(to) {
	window.Munchkin && window.Munchkin.munchkinFunction('clickLink',{href: 'sponsorship-app/started-building-proposal'});
	window.ga('send', 'event', 'Sponsorship', window.location.pathname, 'Download Email: ' + to.slice(10));
}

/* Download Email Links in Download Section of Package Builder */
const DownloadEmail = ({ packageName, openModal, disable }) => {
	if ( packageName === 'Email' || packageName === 'Registration') {
        disable.length = 2;
    }

    const emailTo = (disable.length < 1 ? `/packages/${packageName}` : `/packages/${packageName}/email-proposal`);
	return (
			<div className="download-item-wrapper">
				<div className="download-description-wrapper">
					<span className="download-symbol icon-email-big-alternate"/>
					<div className="download-description">
						<h4>Create An Email Proposal</h4>
						<p className="smaller">Select this option to generate an email that includes the proposal as an attached document.</p>
					</div>
				</div>

				<div className="download-btns-wrapper">
					<button className="green-outline no-max-width" onClick={() => openModal(packageName, 'email')}>See Example</button>
					<Link 
						to={ emailTo }
						className={'button download-button no-max-width ' + (disable.length < 1 ? 'disabled' : 'enabled')}
						disabled={disable.length < 1}
						onClick={ marketoSub.bind(null, emailTo) }>
						<span className="icon-email-small"/>Email</Link>
				</div>
			</div>
		);
};

export default DownloadEmail;
