import React from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

const PackageBuilder = (props) => {
	const page = props.location.pathname.substr(1);
	return(
		<section className="package-builder-section">
			<div className="container package-builder-container">
                <TransitionGroup>
                    <CSSTransition
                        key={page}
                        className="fade"
                        timeout={{ enter: 500, exit: 1 }}>
                        {React.cloneElement( props.children, {key: page})}
                    </CSSTransition>
                </TransitionGroup>
			</div>
		</section>
	);
}

export default PackageBuilder;
